/* eslint-disable no-useless-escape */
export default function validate(data, attribute, error, isNumeric) {
  const formError = error;
  formError[attribute] = data === "";
  if (isNumeric) {
    formError[attribute] = !(parseInt(data) == data);
  }
  if (attribute === "email") formError[attribute] = emailValidation(data);
  return formError;
}

export function emailValidation(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return !re.test(email);
}
export function locationValidation(form, formError) {
  if (form.nationality === "105") {
    formError.CF = !form.CF;
    formError.region = !form.region;
    formError.province = !form.province;
    formError.location = !form.location;
    formError.city = !form.city;
    formError.route = !form.route;
    formError.civic = !form.civic;
    formError.cap = !form.cap;
  } else {
    formError.complete_address = !form.complete_address;
  }
  return formError;
}
