import {
	CardGiftcard,
	Create,
	CreateOutlined,
	DeleteOutlined,
	FavoriteTwoTone,
	ListAlt,
	LoyaltyOutlined,
	OpenInBrowser,
	PersonAddDisabled,
	PictureAsPdf,
	SwapHorizontalCircle,
	VisibilityOutlined
} from '@material-ui/icons';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton, Switch, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GeneralTable from '../../NewComponent/GeneralTable';
import requests, { baseURL } from '../../../Services/HttpServices';
import book from '../../../assets/book.gif';

const newtableHeaderFilter = [
	{ name: '', address: '', aCode: '', qrCode: '' },
	{ name: '', deceasedData: '', dedic: '', time: '' },
	{ partner: '', type: '', sdi: '', iva: '' },
	{ name: '', deceased: '', qrcode: '' },
	{ name: '', deceased: '' }
];
const isDeceasedActive = [];
const statusBool = [];
const newtableHeaderName = [
	[
		// { id: 'id', label: 'ID', minWidth: 10, maxWidth: 50, align: 'center' },
		{ id: 'name', label: 'Nome', align: 'center' },
		{ id: 'address', label: 'Indirizzo', align: 'center' },
		{ id: 'aCode', label: 'Codice di attivazione', align: 'center' },
		{ id: 'qrCode', label: 'QrCode', align: 'center' },
		{ id: 'action', label: 'Azioni\u00a0\u00a0\u00a0', align: 'center' }
	],
	[
		{ label: 'Nome', id: 'name', align: 'center' },
		{ label: 'Dati defunto', id: 'deceasedData', align: 'center' },
		{ label: 'Tipologia di dedica', id: 'dedic', align: 'center' },
		{ label: 'Data e ora', id: 'time', align: 'center' },
		{ label: 'Azioni', id: 'action' }
	],

	[
		{ label: 'Dati partner', id: 'partner', align: 'center' },
		{ label: 'Nome utente', id: 'username', align: 'center' },
		{ label: 'Tipologia azienda', id: 'type', align: 'center' },
		{ label: 'Temporanei', id: 'temp', align: 'center' },
		{ label: 'Disponibili', id: 'avalaible', align: 'center' },
		{ label: 'Azioni', id: 'action', align: 'center' }
	],
	[
		{ label: 'Proprietario', row: 'name', id: 'owner', align: 'center' },
		{ label: 'Defunto', row: 'fat', id: 'deceased', align: 'center' },
		{ label: 'Conto vendita', id: 'temp', row: 'calories', align: 'center' },
		{ label: 'Data di attivazione', id: 'dataA', row: 'calories', align: 'center' },
		{ label: 'Azioni', id: 'action', align: 'center', minWidth: 287 },
		{ label: 'N° condoglianze', id: 'book', align: 'center' }
	],
	[
		{ label: 'Proprietario', row: 'name', id: 'owner', align: 'center' },
		{ label: 'Defunto', row: 'fat', id: 'deceased', align: 'center' },
		{ label: 'Azioni', id: 'action', align: 'center' }
	]
];
export default function AdminPanelTableWrapper(props) {
	return (
		<GeneralTable
			filter={newtableHeaderFilter[props.type]}
			extradata
			reload={props.reload}
			deceasedAdminTable={props.admin && props.type === 3}
			deleteAllOperation={props.deleteAllOperation}
			setChecked={props.setChecked}
			setFilter={props.setFilter}
			setNumber={() => {}}
			setAllNumber={() => {}}
			headData={newtableHeaderName[props.type]}
			data={props.data}
			actionData={
				<div>
					<CreateOutlined fontSize="small" className="dash-icon" />
					<VisibilityOutlined fontSize="small" className="dash-icon" />
					<DeleteOutlined fontSize="small" className="dash-icon" />
				</div>
			}
		/>
	);
}

export function createDeceasedDataTable(
	id,
	owner,
	deceased,
	qrcode,
	setState,
	isMemorial,
	dedicNumber,
	history,
	deceasedData,
	setDisabled,
	enabled,
	restore,
	index,
	setContoDisabled,
	restoreConto
) {
	isDeceasedActive[index] = deceasedData?.enabled === '1';
	statusBool[index] = `${deceasedData.contoVendita}` === 'Si';
	const status = (
		<Switch
			key={`stat${id}  ${timestamp}${index}`}
			defaultChecked={statusBool[index]}
			onChange={(event, checked) => {
				requests('post', 'updateConto', { deceasedID: `000${id}` }).then(response => {
					setContoDisabled(checked);
					restore(checked);
				});
			}}
		/>
	);
	let timestamp = 0;
	const action = (
		<div>
			<Tooltip title="Lapide abilitata?">
				<Switch
					size="small"
					key={`switch${id}  ${timestamp}`}
					id={`switch${id}`}
					defaultChecked={isDeceasedActive[index]}
					onChange={(event, checked) => {
						requests('post', 'updateDeceased', { deceasedID: `000${id}` }).then(response => {
							isDeceasedActive[index] = checked;
							timestamp = new Date().getTime();
							setState({ load: checked });
							setDisabled(checked);
							restore(checked);
						});
					}}
					fontSize="small"
					className="dash-icon"
				/>
			</Tooltip>
			<Tooltip title="Aggiungi dono">
				<IconButton
					size="small"
					onClick={() => history.push(`/aggiungi-dono-admin?000${id}`)}
					className="icon-button"
				>
					<CardGiftcard color="primary" fontSize="small" className="dash-icon" />
				</IconButton>
			</Tooltip>
			{isMemorial ? (
				<Tooltip title="Modifica memorial">
					<IconButton
						size="small"
						onClick={() => {
							window.history.pushState(
								'object or string',
								'Title',
								`/admin-panel?DeceasedID=000${id}#modifica-memorial`
							);
							setState({
								dedicDeceasedID: id,
								type: 11
							});
						}}
						className="icon-button"
					>
						<LoyaltyOutlined
							style={{ color: 'pink', cursor: 'pointer' }}
							fontSize="small"
							className="dash-icon"
						/>
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Aggiungi memorial">
					<IconButton
						size="small"
						onClick={() => {
							window.history.pushState(
								'object or string',
								'Title',
								`/admin-panel?DeceasedID=000${id}#aggiungi-memorial`
							);
							setState({
								dedicDeceasedID: id,
								type: 10
							});
						}}
						className="icon-button"
					>
						<FavoriteTwoTone
							fontSize="small"
							style={{ color: 'red', cursor: 'pointer' }}
							className="dash-icon"
						/>
					</IconButton>
				</Tooltip>
			)}
			<Tooltip title="Elimina Lapide digitale">
				<IconButton
					size="small"
					onClick={() =>
						setState({
							modalClear: true,
							labelModalClear: `la lapide digitale di ${deceased} `,
							deceasedTableID: id
						})
					}
					className="icon-button"
				>
					<ClearIcon fontSize="small" color="error" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title="Visualizza lapide digitale">
				<IconButton
					size="small"
					onClick={() => {
						const win = window.open(`/memorial?000${id}`, '_blank');
						win.focus();
					}}
					className="icon-button"
				>
					<VisibilityIcon fontSize="small" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title="Modifica lapide digitale">
				<IconButton
					size="small"
					onClick={() => setState({ openModifyDeceased: true, pageModifyData: deceasedData })}
					className="icon-button"
				>
					<Create color="primary" fontSize="small" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title={dedicNumber > 0 ? `Gestione dediche: ${dedicNumber}` : 'Nessuna dedica associata'}>
				<IconButton
					size="small"
					onClick={() => {
						if (`${dedicNumber}` !== '0') {
							window.history.pushState(
								'object or string',
								'Title',
								`/admin-panel?DeceasedID=000${id}#visualizza-dediche`
							);
							setState({
								dedicDeceasedID: id
							});
						}
					}}
					className="icon-button"
				>
					<ListAlt
						color={(dedicNumber > 0 && 'primary') || 'disabled'}
						fontSize="small"
						className="dash-icon"
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title="Cambia proprietario pagina">
				<IconButton
					size="small"
					onClick={() =>
						setState({
							selectedID: id,
							propertyModal: true
						})
					}
					className="icon-button"
				>
					<SwapHorizontalCircle fontSize="small" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title="Visualizza PDF libro delle condoglianze">
				<IconButton
					size="small"
					onClick={() => {
						setState({ modalLoading: true, loadingLabel: 'Creazione del pdf...' });
						requests('get', 'pdfBook', id).then(data => {
							if (data?.data?.response === 'success') {
								setState({ modalLoading: false });
								if (window.showSaveFilePicker)
									fetch(data.data.data.pdf.replace('www.', ''), {
										method: 'GET',
										headers: {}
									})
										.then(response => {
											response.arrayBuffer().then(function (buffer) {
												const url = window.URL.createObjectURL(new Blob([buffer]));
												const link = document.createElement('a');
												link.href = url;

												link.setAttribute('download', `Libro-${id}.pdf`); // or any other extension
												link.setAttribute('target', `_blank`); // or any other extension
												document.body.appendChild(link);
												link.click();
												document.body.removeChild(link);
												setState({
													feedbackModal: true,
													feedbackModalLabel: 'Il pdf è stato scaricato con successo',
													feedbackModalResult: 'Successo',
													modalLoading: false
												});
											});
										})
										.catch(err => {
											console.log(err);
										});
								else {
									const win = window.open(data.data.data.pdf, '_blank');
									win.focus();
									setState({
										feedbackModal: true,
										feedbackModalLabel: 'Il pdf è stato scaricato con successo',
										feedbackModalResult: 'Successo',
										modalLoading: false
									});
								}
							} else
								setState({
									feedbackModal: true,
									feedbackModalLabel: data?.data?.message || 'Errore generico... riprovare',
									feedbackModalResult: 'Errore',
									modalLoading: false
								});
						});
					}}
					className="icon-button"
				>
					<img
						alt="pdf"
						src={book}
						style={{ filter: deceasedData.contoVendita !== '1' ? 'none' : 'grayscale()', width: 24 }}
						className="dash-icon"
					/>
				</IconButton>
			</Tooltip>
		</div>
	);
	const extradata = (
		<div className="radius-16" style={{ padding: 10, borderRadius: 16 }}>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Nome utente:</strong> {owner}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Telefono:</strong> {deceasedData.phone}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Username:</strong> {deceasedData.email}
			</Typography>
			<Typography
				onClick={() => setState({ qrCodeImg: deceasedData.link, showQrCode: true })}
				style={{ fontSize: '0.8rem', color: 'skyblue', cursor: 'pointer' }}
			>
				<strong>Qrcode:</strong> {qrcode.replace('-lapide', '')}
			</Typography>
		</div>
	);
	return {
		owner,
		deceased,
		dataA: deceasedData.time,
		temp: status,
		qrcode: qrcode.replace('-lapide', ''),
		extradata,
		enabled,
		status,
		action,
		book: `${deceasedData.condolence || 0}`
	};
}

export function createActivationDataTable(id, name, address, aCode, qrCode, setState, data) {
	const action = (
		<div>
			<Tooltip title="Elimina qrCode">
				<IconButton
					size="small"
					onClick={() =>
						setState({
							modalClear: true,
							labelModalClear: `la richiesta di attivazione dell'utente ${name} `,
							deceasedToDelete: id
						})
					}
					className="icon-button"
				>
					<ClearIcon fontSize="small" className="dash-icon" />
				</IconButton>
			</Tooltip>
		</div>
	);
	const extradata = (
		<div className="radius-16" style={{ padding: 10, borderRadius: 16 }}>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Nome utente:</strong> {name}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Telefono:</strong> {data.phone}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Username:</strong> {data.email}
			</Typography>
		</div>
	);
	return { name, address, aCode, qrCode, extradata, action };
}

export function createObituaryDataTable(id, owner, deceased, setState, data) {
	const action = (
		<div>
			<Tooltip title="Elimina Necrologio">
				<IconButton
					size="small"
					onClick={() =>
						setState({
							modalClear: true,
							labelModalClear: `il necrologio di ${owner} `,
							obituaryToDelete: id
						})
					}
					className="icon-button"
				>
					<ClearIcon fontSize="small" color="error" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title="Modifica Necrologio">
				<IconButton
					size="small"
					onClick={() =>
						setState({
							openModifyObituary: true,
							obituaryModifyData: data
						})
					}
					className="icon-button"
				>
					<Create color="primary" fontSize="small" className="dash-icon" />
				</IconButton>
			</Tooltip>
		</div>
	);
	const extradata = (
		<div className="radius-16" style={{ padding: 10, borderRadius: 16 }}>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Nome utente:</strong> {owner}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Telefono:</strong> {data.phone}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Username:</strong> {data.email}
			</Typography>
		</div>
	);
	return { owner, deceased, action, extradata };
}

export function createPartnerDataTable(id, partner, type, sdi, iva, partnerData, State, checkOperation) {
	const action = (
		<div>
			{`${partnerData.active}` !== '1' && (
				<Tooltip title={`Accetta la richiesta del partner ${partner}`}>
					<IconButton
						size="small"
						onClick={() =>
							State({
								alertGeneral: true,
								alertGeneralText: `Sei sicuro di voler accettare la richiesta del partner ${partner} ?`,
								partnerToAccept: id,
								alertGeneralFunction: () => checkOperation()
							})
						}
						className="icon-button"
					>
						<CheckIcon fontSize="small" style={{ color: 'green' }} className="dash-icon" />
					</IconButton>
				</Tooltip>
			)}
			{`${partnerData.active}` !== '1' && (
				<Tooltip title={`Elimina la richiesta del partner ${partner}`}>
					<IconButton
						size="small"
						onClick={() =>
							State({
								modalClear: true,
								labelModalClear: `la richiesta del partner ${partner} `,
								partnerToDelete: id
							})
						}
						className="icon-button"
					>
						<ClearIcon fontSize="small" color="error" className="dash-icon" />
					</IconButton>
				</Tooltip>
			)}
			{`${partnerData.active}` === '1' && (
				<Tooltip title={`Disabilita partner ${partner}`}>
					<IconButton
						size="small"
						onClick={() => {
							State({
								alertGeneral: true,
								alertGeneralText: `Sei sicuro di voler disabilitare il partner ${partner}?`,
								alertGeneralFunction: () =>
									requests('post', 'downgradePartner', {
										partnerid: id
									}).then(data => {
										window.location.reload();
									})
							});
						}}
						className="icon-button"
					>
						<PersonAddDisabled style={{ color: 'darkred', cursor: 'pointer' }} />
					</IconButton>
				</Tooltip>
			)}
			<Tooltip title="Modifica pagina partner">
				<IconButton
					size="small"
					onClick={() =>
						State({
							partnermodal: true,
							partnerToShow: id
						})
					}
					className="icon-button"
				>
					<Create fontSize="small" color="primary" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title="Visualizza pagina partner">
				<IconButton
					size="small"
					onClick={() => {
						const win = window.open(`/partner-page?${partnerData.ID}`, '_blank');
						win.focus();
					}}
					className="icon-button"
				>
					<VisibilityIcon fontSize="small" style={{ color: '#ff8300' }} className="dash-icon" />
				</IconButton>
			</Tooltip>
		</div>
	);
	const extradata = (
		<div className="radius-16" style={{ padding: 10, borderRadius: 16 }}>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Nome utente:</strong> {partner}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Telefono:</strong> {partnerData.telephone || 'Non disponibile'}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Email:</strong> {partnerData.email}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Website:</strong> {partnerData.website}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Sdi:</strong> {sdi}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>IVA:</strong> {iva}
			</Typography>
		</div>
	);
	return {
		partner,
		type,
		sdi,
		temp: partnerData.qrcodeTemporanei,
		avalaible: partnerData.qrcodeDisponibili,
		iva,
		extradata,
		username: partnerData.username,
		action
	};
}

export function createDedicDataTable(id, name, deceasedData, dedic, time, token, dedicData, checkOP, deleteOP, viewOP) {
	const action = (
		<div>
			<Tooltip title={`Accetta richiesta di ${name}`}>
				<IconButton size="small" onClick={() => checkOP()} className="icon-button">
					<CheckIcon fontSize="small" style={{ color: 'green' }} className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title={`Rifiuta la richiesta di ${name}`}>
				<IconButton size="small" onClick={() => deleteOP()} className="icon-button">
					<ClearIcon fontSize="small" color="error" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title={`Visualizza dedica di ${name}`}>
				<IconButton size="small" onClick={() => viewOP()} className="icon-button">
					<VisibilityIcon fontSize="small" color="primary" className="dash-icon" />
				</IconButton>
			</Tooltip>
		</div>
	);
	const extradata = (
		<div className="radius-16" style={{ padding: 10, borderRadius: 16 }}>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Nome utente:</strong> {name}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Telefono:</strong> {dedicData.dedicOwner?.telephone || 'non inserito'}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Email:</strong> {dedicData.dedicOwner?.email || 'non inserito'}
			</Typography>
			<Typography style={{ fontSize: '0.8rem' }}>
				<strong>Username:</strong> {dedicData.dedicOwner?.username || 'non inserito'}
			</Typography>
		</div>
	);
	return { name, deceasedData, dedic, time, extradata, action };
}
